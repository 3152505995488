import * as React from "react"

import LayoutFashionTech from "../components/LayoutFashionTech"
import FashionTechPicture1 from "../components/FashionTechPage/FashionTechPicture1"
import FashionTechText1 from "../components/FashionTechPage/FashionTechText1"
import FashionTechPicture2 from "../components/FashionTechPage/FashionTechPicture2"
import FashionTechText2 from "../components/FashionTechPage/FashionTechText2"
import FashionTechPicture3 from "../components/FashionTechPage/FashionTechPicture3"
import FashionTechText3 from "../components/FashionTechPage/FashionTechText3"
import FashionTechPicture4 from "../components/FashionTechPage/FashionTechPicture4"
import FashionTechPicture5 from "../components/FashionTechPage/FashionTechPicture5"
import FashionTechText4 from "../components/FashionTechPage/FashionTechText4"
import FashionTechTextIntro from "../components/FashionTechPage/FashionTechTextIntro"
import FashionTechText5 from "../components/FashionTechPage/FashionTechText5"
import FashionTechNewsletter from "../components/FashionTechPage/FashionTechNewsletter"
import BusinessOpportunityBar from "../components/FashionTechPage/BusinessOpportunityBar"
import ResaleRentalBPBar from "../components/FashionTechPage/ResaleRentalBPBar"

function FashionTechPage({ data }) {
  return (
    <LayoutFashionTech>
      <FashionTechTextIntro/>
      <BusinessOpportunityBar/>
      <ResaleRentalBPBar/>
      <FashionTechPicture1/>
      <FashionTechText1/>
      <FashionTechPicture2/>
      <FashionTechText2/>
      <FashionTechPicture3/>
      <FashionTechText3/>
      <FashionTechPicture4/>
      <FashionTechText4/>
      <FashionTechPicture5/>
      <FashionTechText5/>
      <FashionTechNewsletter />
    </LayoutFashionTech>
  )
}


export default FashionTechPage