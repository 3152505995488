import React, { useEffect } from "react";
import "../scss/FashionTechTextIntro.scss";
import { graphql, useStaticQuery } from "gatsby";
import Aos from "aos";
import "aos/dist/aos.css";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { Helmet } from "react-helmet";

export default function FashionTechTextIntro() {
  const data = useStaticQuery(graphql`
  query MyQuery39 {
    allContentfulTextBlock(
      filter: { id: { eq: "17a421a9-06e4-52c0-ada3-6011088c3e5b" } }
    ) {
      edges {
        node {
          title
          bodyText {
            raw
          }
          id
        }
      }
    }
  }
  `);

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const {
    title,
    bodyText,
  } = data.allContentfulTextBlock.edges[0].node;

  const options = {
    renderText: (text) => {
      return text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]);
    },
  };

  return (
    <>
      <Helmet>
        <title>Luxury Leaders Handbook to Resale- Rental & Plus Size</title>
        <meta name="description" content="A Luxury Leaders Handbook to Resale – Rental & Plus Size. Grow your Business in The New Circular Economy" />
        <meta name="keywords" content="Luxury Circular Economy, Luxury Circular Economy examples, Circular economy diagram" />
        <h1>Grow your Luxury Fashion Business with Resale, Rental & Plus Sizing</h1>
        <h2>Grow your Luxury Fashion Business with Resale, Rental & Plus Sizing</h2>
      </Helmet>
    <div className="contentFeatureFashionTechIntro">
      <div className={'textContainerFashionTechIntro'}>
        <p className={'titleFashionTechIntro'}>{title}</p>
        <p className='bodyFashionTechIntro'>
          {documentToReactComponents(
            JSON.parse(bodyText.raw),
            options
          )}
        </p>
      </div>
    </div>
    </>
  );
}
