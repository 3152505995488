import React, { useEffect } from "react";
import "../scss/FashionTechText4.scss";
import { graphql, useStaticQuery } from "gatsby";
import Aos from "aos";
import "aos/dist/aos.css";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Helmet } from "react-helmet";

export default function FashionTechText4() {
  const data = useStaticQuery(graphql`
    query MyQuery62 {
      allContentfulTextBlock(
        filter: { id: { eq: "7301f550-e677-5280-9180-e7f8ea9302a7" } }
      ) {
        edges {
          node {
            title
            bodyText {
              raw
            }
            body1
            body2
            body3
            id
          }
        }
      }
    }
  `);

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const {
    title,
    body1,
    body2,
    body3
  } = data.allContentfulTextBlock.edges[0].node;

  const options = {
    renderText: (text) => {
      return text.split("\n").map((line, i) => (
        <React.Fragment key={i}>
          {i > 0 && <br />}
          {line}
        </React.Fragment>
      ));
    },
  };

  const addBulletPoints = (text) => {
    const sentences = text.split("\n");
    return sentences.map((sentence, index) => (
      <div key={index}>
        <span className="bulletPoint3">• </span>
        {sentence}
      </div>
    ));
  };

  return (
    <>
      <Helmet>
        <h2>Get a Complete Guide to Luxury Resale & Rental Websites</h2>
      </Helmet>
    <div className="contentFeatureFashionTechText4">
      <div className="FTText4">
        <div className="TitleText4">{title}</div>
        <div className="witemsFT4">
          <div className="witem1FT4">
            <div className="wtitle1FT4">
              <strong>
              {addBulletPoints(body1)}
              </strong>
            </div>
          </div>
          <div className="witem2FT4">
            <div className="wtitle2FT4">
              <strong>
              {addBulletPoints(body2)}
              </strong>
            </div>
          </div>
          <div className="witem3FT4">
            <div className="wtitle3FT4">
              <strong>
              {addBulletPoints(body3)}
              </strong>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}
