import React, { useEffect } from "react";
import "../scss/FashionTechText2.scss";
import { graphql, useStaticQuery } from "gatsby";
import Aos from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";

export default function FashionTechText() {
  const data = useStaticQuery(graphql`
    query MyQuery41 {
      allContentfulTextBlock(filter: { id: { eq: "9a526e30-89df-542a-8735-d3a2820172e6" } }) {
        edges {
          node {
            body1
            body2
            body3
            title
            id
          }
        }
      }
    }
  `);

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const {
    title,
    body1,
    body2,
    body3
  } = data.allContentfulTextBlock.edges[0].node;

  const addBulletPoints = (text) => {
    const sentences = text.split("\n");
    return sentences.map((sentence, index) => (
      <div key={index}>
        <span className="bulletPoint">• </span>
        {sentence}
      </div>
    ));
  };

  return (
    <>
      <Helmet>
        <h2>Grow in these Emerging Markets</h2>
      </Helmet>
    <div className="contentFeatureFashionTechText2">
      <div className="FTText2">
        <div className="TitleText2">{title}</div>
        <div className="witemsFT1">
          <div className="witem1FT1">
            <div className="wtitle1FT1">
              <strong>
                {addBulletPoints(body1)}
              </strong>
            </div>
          </div>
          <div className="witem2FT1">
            <div className="wtitle2FT1">
              <strong>
                {addBulletPoints(body2)}
              </strong>
            </div>
          </div>
          <div className="witem3FT1">
            <div className="wtitle3FT1">
              <strong>
                {addBulletPoints(body3)}
              </strong>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}
