import React, { useEffect } from "react";
import "../scss/FashionTechText3.scss";
import { graphql, useStaticQuery } from "gatsby";
import Aos from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";

export default function FashionTechText3() {
  const data = useStaticQuery(graphql`
    query MyQuery61 {
      allContentfulTextBlock(
        filter: { id: { eq: "f91bb171-18b4-5644-a357-46e46ab33295" } }
      ) {
        edges {
          node {
            title
            bodyText {
              raw
            }
            body1
            body2
            body3
            id
          }
        }
      }
    }
  `);

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const {
    title,
    body1,
    body2,
    body3
  } = data.allContentfulTextBlock.edges[0].node;

  const addBulletPoints = (text) => {
    const sentences = text.split("\n");
    return sentences.map((sentence, index) => (
      <div key={index}>
        <span className="bulletPoint2">• </span>
        {sentence}
      </div>
    ));
  };

  return (
    <>
      <Helmet>
        <h2>Understand and Attract New Customers </h2>
      </Helmet>
    <div className="contentFeatureFashionTechText3">
      <div className="FTText3">
        <div className="TitleText3">{title}</div>
        <div className="witemsFT3">
          <div className="witem1FT3">
            <div className="wtitle1FT3">
              <strong>
              {addBulletPoints(body1)}
              </strong>
            </div>
          </div>
          <div className="witem2FT3">
            <div className="wtitle2FT3">
              <strong>
              {addBulletPoints(body2)}
              </strong>
            </div>
          </div>
          <div className="witem3FT3">
            <div className="wtitle3FT3">
              <strong>
              {addBulletPoints(body3)}
              </strong>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}