import React from "react";
import { graphql, useStaticQuery } from 'gatsby';
import "../scss/BusinessOpportunityBar.scss";
import { Helmet } from "react-helmet";

const BusinessOpportunityBar = () => {
  const data = useStaticQuery(graphql`
    query MyQueryFT {
      allContentfulValuePropositionBar(filter: { id: { eq: "b4b6a451-b38c-5f75-b80b-870fbee7a47f" } }) {
        edges {
          node {
            bodyText
            bodyText2
            bodyText3
            title
            title2
            title3
            image3 {
              altText
              image {
                file {
                  url
                }
              }
            }
            image2 {
              altText
              image {
                file {
                  url
                }
              }
            }
            image {
              altText
              image {
                file {
                  url
                }
              }
            }
            id
            outsideTitle
          }
        }
      }
    }
  `);

  const {
    title,
    title2,
    title3,
    bodyText,
    bodyText2,
    bodyText3,
    image,
    image2,
    image3,
  } = data.allContentfulValuePropositionBar.edges[0].node;
  const [name3FT, description3FT] = title3.split("Customers");

  return (
    <>
      <Helmet>
        <h2>The Business Opportunity</h2>
      </Helmet>
    <div className="contentFeatureWhatIDoFT">
      <div className="wbarFT">
        <div className="outsideTitleFT">The Business Opportunity</div>
        <div className="witemsFT">
          <div className="witem1FT">
            <div className="wtitle1FT"><strong>{title}</strong></div>
            <img className="wimage1FT" src={image.image.file.url} alt={image.altText} />
            <div className="wsentence1FT">{bodyText}</div>
          </div>
          <div className="witem2FT">
            <div className="wtitle2FT"><strong>{title2}</strong></div>
            <img className="wimage2FT" src={image2.image.file.url} alt={image2.altText} />
            <div className="wsentence2FT">{bodyText2}</div>
          </div>
          <div className="witem3FT">
            <div className="wtitle3FT"><strong>{title3}</strong></div>
            <img className="wimage3FT" src={image3.image.file.url} alt={image3.altText} />
            <div className="wsentence3FT">{bodyText3}</div>
          </div>
          {/* <div className="witem3FT">
            <div className="wtitle3FT"><strong>
              {name3FT}
              <span className="highlight3About">Customers</span><br />
              {description3FT}
            </strong></div>
            <img className="wimage3FT" src={image3.image.file.url} alt={image3.altText} />
            <div className="wsentence3FT">{bodyText3}</div>
          </div> */}
        </div>
      </div>
    </div>
    </>
  );
};

export default BusinessOpportunityBar;
