import React, { useEffect, useState } from "react"
import "../scss/FashionTechPicture2.scss"
import { graphql, useStaticQuery } from "gatsby"
import Aos from "aos";
import "aos/dist/aos.css";
import chart1 from '../../images/chart.png';

export default function FashionTechPicture2() {
  const data = useStaticQuery(graphql`
  query MyQuery51 {
    allContentfulImageBlock(filter: { name: { eq: "Fashion Tech Page Pic 2" } }) {
      edges {
        node {
          image {
            image {
              file {
                url
              }
            }
          }
          name
        }
      }
    }
  }
  `)

  useEffect(() => {
    Aos.init({duration: 1000, offset: -60});
  }, []);

  if (data.allContentfulImageBlock.edges.length === 0) {
    return null; // Handle the case when the desired node is not found
  }

  return (
    <div className="contentFeatureFashionTechPic2">
      <div data-aos="fade-up" className="boardTextContainerFashionTechPic2 fade-in">

        <div className={"displayBoardContainerFashionTechPic2"}>
          <div className="displayBoardFashionTechPic2"></div>
        </div>
      </div>
      

      <div className={'textContainerFashionTechPic2'}>
          {/* <p className={'titleFashionTechPic2'}>{data.allContentfulFlexStyleBody.edges[5].node.title}</p> */}
      </div>
      <div className={"imageContainerFashionTechPic2"}>
        <img
          data-aos="fade-up"
          className={"bigImagFashionTechPic2"}
          src={data.allContentfulImageBlock.edges[0].node.image.image.file.url}
          alt="Luxury Resale Market"
        />
      </div>
    </div>
    
  )
}