import React, { useEffect } from "react";
import "../scss/FashionTechText5.scss";
import { graphql, useStaticQuery } from "gatsby";
import Aos from "aos";
import "aos/dist/aos.css";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Helmet } from "react-helmet";

export default function FashionTechText5() {
  const data = useStaticQuery(graphql`
    query MyQuery71 {
      allContentfulTextBlock(
        filter: { id: { eq: "64669b85-a048-598d-93ff-86a371cfbfe2" } }
      ) {
        edges {
          node {
            title
            bodyText {
              raw
            }
            body1
            body2
            body3
            id
          }
        }
      }
    }
  `);

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const {
    title,
    body1,
    body2,
    body3
  } = data.allContentfulTextBlock.edges[0].node;

  const options = {
    renderText: (text) => {
      return text.split("\n").map((line, i) => (
        <React.Fragment key={i}>
          {i > 0 && <br />}
          {line}
        </React.Fragment>
      ));
    },
  };

  const addBulletPoints = (text) => {
    const sentences = text.split("\n");
    return sentences.map((sentence, index) => (
      <div key={index}>
        <span className="bulletPoint4">• </span>
        {sentence}
      </div>
    ));
  };

  return (
    <>
      <Helmet>
        <h2>Learn how to monetize these markets today</h2>
      </Helmet>
    <div className="contentFeatureFashionTechText5">
      <div className="FTText5">
        <div className="TitleText5">{title}</div>
        <div className="witemsFT5">
          <div className="witem1FT5">
            <div className="wtitle1FT5">
              <strong>
              {addBulletPoints(body1)}
              </strong>
            </div>
          </div>
          <div className="witem2FT5">
            <div className="wtitle2FT5">
              <strong>
              {addBulletPoints(body2)}
              </strong>
            </div>
          </div>
          <div className="witem3FT5">
            <div className="wtitle3FT5">
              <strong>
              {addBulletPoints(body3)}
              </strong>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}