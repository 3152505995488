import * as React from "react";
import FeatureBar from "./FeatureBar";
import FeatureBarPink from "./FeatureBarPink";
import Footer from "./Footer";
import "./Layout.css";
import NavBar from "./Nav/NavBar";
import NewsletterBar from "./NewsletterBar";
import CookieBanner from "./CookieBanner";
import HeroImageFashionTech from "../components/FashionTechPage/HeroImageFashionTech";

const LayoutFashionTech = ({ children }) => {
  React.useEffect(() => {
    document.body.style.backgroundColor = '#f6f2e2'; // Set the background color of the body element
    return () => {
      document.body.style.backgroundColor = ''; // Reset the background color when the component unmounts
    };
  }, []);

  return (
    <div>
      <CookieBanner />
      <NavBar />
      <FeatureBarPink />
      <FeatureBar />
      <HeroImageFashionTech />

      <div>{children}</div>

      {/* <NewsletterBar />
      <Footer /> */}
    </div>
  );
};

export default LayoutFashionTech;
