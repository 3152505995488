import React from "react";
import { graphql, useStaticQuery } from 'gatsby';
import "../scss/ResaleRentalBPBar.scss";

const ResaleRentalBPBar = () => {
  const data = useStaticQuery(graphql`
    query MyQueryRRBP {
      allContentfulValuePropositionBar(filter:{id:{eq:"4ac86e1b-16e7-52dc-88fa-66b48cd2b9b6"}}) {
        edges {
          node {
            id
            picture5 {
              file {
                url
              }
            }
            picture4 {
              file {
                url
              }
            }
            picture3 {
              file {
                url
              }
            }
            picture2 {
              file {
                url
              }
            }
            picture1 {
              file {
                url
              }
            }
            outsideTitle
          }
        }
      }
    }
  `);

  const {
    outsideTitle,
    picture1,
    picture2,
    picture3,
    picture4,
    picture5,
  } = data.allContentfulValuePropositionBar.edges[0].node;

  return (
    <div className="contentFeatureRR">
    <div className="wbarRR">
      <div className="outsideTitleRR">{outsideTitle}</div>
      <div className="witemsRR">
        <div className="row1">
          <div className="witem1RR">
            <img className="wimage1RR" src={picture1.file.url} alt={picture1.altText} />
          </div>
          <div className="witem2RR">
            <img className="wimage2RR" src={picture4.file.url} alt={picture4.altText} />
          </div>
          <div className="witem3RR">
            <img className="wimage3RR" src={picture3.file.url} alt={picture3.altText} />
          </div>
        </div>
        <div className="row2">
          <div className="witem4RR">
            <img className="wimage4RR" src={picture5.file.url} alt={picture5.altText} />
          </div>
          <div className="witem5RR">
            <img className="wimage5RR" src={picture2.file.url} alt={picture2.altText} />
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default ResaleRentalBPBar;