import React, { useEffect, useState } from "react"
import "../scss/FashionTechPicture4.scss"
import { graphql, useStaticQuery } from "gatsby"
import Aos from "aos";
import "aos/dist/aos.css";
import smile from '../../images/smile_final.png';


export default function FashionTechPicture4() {
  const data = useStaticQuery(graphql`
  query MyQuery53 {
    allContentfulImageBlock(filter: { name: { eq: "Fashion Tech Page Pic 4" } }) {
      edges {
        node {
          image {
            image {
              file {
                url
              }
            }
          }
          name
        }
      }
    }
  }
  `)

  useEffect(() => {
    Aos.init({duration: 1000, offset:-700});
  }, []);

  if (data.allContentfulImageBlock.edges.length === 0) {
    return null; // Handle the case when the desired node is not found
  }

  return (
    <div className="contentFeatureFashionTechPic4">
      <div data-aos="fade-up" className="boardTextContainerFashionTechPic4 fade-in">
        <div className={"displayBoardContainerFashionTechPic4"}>
          <div className="displayBoardFashionTechPic4"></div>
        </div>
      </div>
      

      <div className={'textContainerFashionTechPic4'}>
          {/* <p className={'titleFashionTechPic4'}>{data.allContentfulFlexStyleBody.edges[0].node.title}</p> */}
      </div>
      
      <div className={"imageContainerFashionTechPic4"}>
      <img
          data-aos="fade-up"
          className={"bigImagFashionTechPic4"}
          src={data.allContentfulImageBlock.edges[0].node.image.image.file.url}
          alt="smile"
        />
      </div>
    </div>
  )
}