import React, { useEffect } from "react";
import "../scss/FashionTechText1.scss";
import { graphql, useStaticQuery } from "gatsby";
import Aos from "aos";
import "aos/dist/aos.css";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { Helmet } from "react-helmet";

export default function FashionTechText() {
  const data = useStaticQuery(graphql`
  query MyQuery40 {
    allContentfulTextBlock(
      filter: { id: { eq: "bc1dd8e8-7228-562b-b0db-d228faab4510" } }
    ) {
      edges {
        node {
          title
          bodyText {
            raw
          }
          id
        }
      }
    }
  }
  `);

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const {
    title,
    bodyText,
  } = data.allContentfulTextBlock.edges[0].node;

  const options = {
    renderText: (text) => {
      return text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]);
    },
  };

  return (
    <>
      <Helmet>
        <h2>The Business Case for Luxury Resale – Rental & Plus Sizing</h2>
      </Helmet>
    <div className="contentFeatureFashionTech">
      <div className={'textContainerFashionTech'}>
        <p className={'titleFashionTech'}>{title}</p>
        <p className='bodyFashionTech'>
          {documentToReactComponents(
            JSON.parse(bodyText.raw),
            options
          )}
        </p>
      </div>
    </div>
    </>
  );
}
