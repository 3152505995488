import React, { useEffect, useState } from "react"
import "../scss/FashionTechPicture1.scss"
import { graphql, useStaticQuery } from "gatsby"
import Aos from "aos";
import "aos/dist/aos.css";
import offwhite from '../../images/offwhite_transparent.png';

export default function FashionTechPicture1() {
  const data = useStaticQuery(graphql`
  query MyQuery50 {
    allContentfulImageBlock(filter: { name: { eq: "Fashion Tech Page Pic 1" } }) {
      edges {
        node {
          image {
            image {
              file {
                url
              }
            }
          }
          name
        }
      }
    }
  }
  `)

  useEffect(() => {
    Aos.init({duration: 1000});
  }, []);
  
  if (data.allContentfulImageBlock.edges.length === 0) {
    return null; // Handle the case when the desired node is not found
  }

  return (
    <div className="contentFeatureFashionTechPic1">
      <div data-aos="fade-up" className="boardTextContainerFashionTechPic1 fade-in">

        <div className={"displayBoardContainerFashionTechPic1"}>
          <div className="displayBoardFashionTechPic1"></div>
        </div>
      </div>

      <div className={'textContainerFashionTechPic1'}>
          {/* <p className={'titleFashionTechPic1'}>{data.allContentfulFlexStyleBody.edges[7].node.title}</p> */}
      </div>
      <div className={"imageContainerFashionTechPic1"}>
        <img
          data-aos="fade-up"
          className={"bigImagFashionTechPic1"}
          src={data.allContentfulImageBlock.edges[0].node.image.image.file.url}
          alt="Luxury Resale"
        />
      </div>
    </div>
    
  )
}