import React, { useEffect, useState } from "react"
import "../scss/FashionTechPicture3.scss"
import { graphql, useStaticQuery } from "gatsby"
import Aos from "aos";
import "aos/dist/aos.css";
import brain from '../../images/brain_transparent.png';

export default function FashionTechPicture3() {
  const data = useStaticQuery(graphql`
  query MyQuery52 {
    allContentfulImageBlock(filter: { name: { eq: "fashion tech brain" } }) {
      edges {
        node {
          image {
            image {
              file {
                url
              }
            }
          }
          name
        }
      }
    }
  }
  `)

  useEffect(() => {
    Aos.init({duration: 1000, offset: -60});
  }, []);

  if (data.allContentfulImageBlock.edges.length === 0) {
    return null; // Handle the case when the desired node is not found
  }

  return (
    <div className="contentFeatureFashionTechPic3">
      <div data-aos="fade-up" className="boardTextContainerFashionTechPic3 fade-in">
        <div className={"displayBoardContainerFashionTechPic3"}>
          <div className="displayBoardFashionTechPic3"></div>
        </div>
      </div>
      

      <div className={'textContainerFashionTechPic3'}>
          {/* <p className={'titleFashionTechPic3'}>{data.allContentfulFlexStyleBody.edges[3].node.title}</p> */}
      </div>
      
      <div className={"imageContainerFashionTechPic3"}>
      <img
          data-aos="fade-up"
          className={"bigImagFashionTechPic3"}
          src={data.allContentfulImageBlock.edges[0].node.image.image.file.url}
          alt="brain"
        />
      </div>
    </div>
    
  )
}